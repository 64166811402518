import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import StructuredTextWrapper from '@ui/components/Content/StructuredTextWrapper';
import { createLegacyTypography, createTypography } from '@ui/styles/base';
import { getImageSizes } from '@ui/utils/getImageSizes';
import Box from '@ui-v2/core/Box/Box';
import Container from '@ui-v2/core/Container/Container';
import Image from '@ui-v2/core/Image/Image';
import SearchWidgetErrorBoundary from '../../Search/SearchWidgetErrorBoundary';
import SearchWidgetWithProvider from '../../SearchWidget/SearchWidget';
import useGetFrontPageData from '../hooks/useGetFrontPageData';

const StyledStaticImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const FrontPageCoverContainer = () => {
  const { cover } = useGetFrontPageData();
  const {
    backgroundTextColor,
    image,
    subtitleColor,
    text,
    textAlign,
    titleColor,
    titleTypography,
    useFloatingSearchWidget,
  } = cover;

  const fixedImageSize = Boolean(
    image?.width && image.height && image.width / image.height > 3,
  );

  return (
    <SearchWidgetErrorBoundary>
      <Box position="relative">
        {useFloatingSearchWidget && (
          <>
            <Box pt={[0, 0, 0, 64]} />
            <Box pt={[0, 0, 56, 80]} />
          </>
        )}
        {image?.url && (
          <Box
            bottom={0}
            left={0}
            position={useFloatingSearchWidget ? 'absolute' : 'static'}
            right={0}
            top={0}
          >
            {useFloatingSearchWidget ? (
              <Image
                alt={image.alt ?? ''}
                fill
                objectFit="cover"
                priority
                quality={75}
                sizes={
                  fixedImageSize
                    ? getImageSizes({ Default: '1920px' })
                    : undefined
                }
                src={image.url}
              />
            ) : (
              <Box display={['none', 'block', 'block']}>
                <StyledStaticImage
                  alt={image.alt ?? ''}
                  height={image.height}
                  priority
                  sizes={
                    fixedImageSize
                      ? getImageSizes({ Default: '1920px' })
                      : undefined
                  }
                  src={image.url}
                  width={image.width}
                />
              </Box>
            )}
          </Box>
        )}
        <Box position="relative" zIndex="2">
          {text && (
            <Container>
              <Box
                borderRadius="borderRadiusS"
                display={['none', 'none', 'block']}
                maxWidthCustom={
                  backgroundTextColor ? ['none', 'none', 500] : 'none'
                }
                px={backgroundTextColor ? 24 : 0}
                py={backgroundTextColor ? 24 : 0}
                style={{
                  background: backgroundTextColor ?? undefined,
                }}
              >
                <StructuredTextWrapper
                  data={
                    text as unknown as StructuredTextType | null | undefined
                  }
                  h1Styles={({ colours, typography }) => [
                    titleTypography
                      ? createLegacyTypography(titleTypography)
                      : createTypography(typography.heading02),
                    css`
                      margin: 0;
                      color: ${titleColor ||
                      colours.text.on.interactive.primary.default};
                      text-align: ${textAlign || 'unset'};
                    `,
                  ]}
                  h2Styles={({ colours, spacings, typography }) => [
                    createTypography(typography.body01),
                    css`
                      margin-top: ${spacings['8']}px;
                      color: ${subtitleColor ||
                      titleColor ||
                      colours.text.on.interactive.primary.default};
                      text-align: ${textAlign || 'unset'};
                    `,
                  ]}
                />
              </Box>
            </Container>
          )}

          <Box
            bg={
              useFloatingSearchWidget
                ? 'transparent'
                : 'components.search.surface.default'
            }
            pb={[24, 24, useFloatingSearchWidget ? 48 : 24]}
            pt={[24, 24, useFloatingSearchWidget ? 72 : 24]}
            px={[16, 16, 24, 24, 24, 24, 0]}
            width="full"
          >
            <Box
              bg="surface.main"
              border="subdued"
              borderRadius="borderRadiusM"
              boxShadow="medium"
              maxWidth="container"
              mx="auto"
              p={16}
              width="full"
            >
              <SearchWidgetWithProvider
                config={{
                  shouldUseLocalStorage: true,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SearchWidgetErrorBoundary>
  );
};

export default FrontPageCoverContainer;
