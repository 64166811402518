import { css, Theme } from '@emotion/react';
import Box, { BoxProps } from '@ui-v2/core/Box/Box';

export interface Props {
  children: React.ReactNode;
  className?: string;
  gap?: BoxProps['gap'];
  variant?: 'default' | 'stacked';
}

export const tileStyles = ({ colours, shadows, shape, spacings }: Theme) => css`
  padding: ${spacings['24']}px ${spacings['16']}px;
  border: 1px solid ${colours.border.subdued};
  border-radius: ${shape.borderRadiusM}px;
  background: ${colours.surface.main};
  box-shadow: ${shadows.medium};
`;

const Tile = ({ children, className, gap, variant = 'default' }: Props) => {
  return (
    <Box
      bg="surface.main"
      border="subdued"
      borderRadius={variant === 'default' ? 'borderRadiusM' : 'borderRadiusS'}
      boxShadow={variant === 'default' ? 'medium' : undefined}
      className={className}
      display="flex"
      flexDirection="column"
      gap={gap}
      p={16}
    >
      {children}
    </Box>
  );
};

export default Tile;
