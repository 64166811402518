import { IconConfigFragment } from '@codegen/cmsUtils';
import CoreButton, {
  ButtonProps as CoreButtonProps,
} from '@ui-v2/core/Button/Button';
import useGetIcon from '@web/hooks/useGetIcon';

export type ButtonProps = Omit<
  CoreButtonProps,
  'icon' | 'iconRight' | 'iconLeft'
> & {
  icon?: keyof IconConfigFragment;
  iconLeft?: keyof IconConfigFragment;
  iconRight?: keyof IconConfigFragment;
};

const Button = ({ icon, iconLeft, iconRight, ...props }: ButtonProps) => {
  const getIcon = useGetIcon();
  const i = icon ? getIcon(icon) : null;
  const iL = iconLeft ? getIcon(iconLeft) : null;
  const iR = iconRight ? getIcon(iconRight) : null;

  return <CoreButton icon={i} iconLeft={iL} iconRight={iR} {...props} />;
};

export default Button;
